<template>
	
<div :class=" { 'blur' : show_modal } ">
	



	<section style="padding-top: 50px;">
		

		<div class="container ">

		<router-link to="/tables" class="btn p-0 color-2"><b> <i class="bi bi-chevron-left"></i> Back</b> </router-link>

		<h1 style="color: #f1f1f1;">Table Reservation</h1>


		<div class="row shadow">

			<div class="col-md-6" style="background-image: url('/assets/images/gallery-02.jpeg'); background-size: cover; background-position: center; min-height: 300px;"></div>
			<div class="col-md-6" style="background-color: #1a1a1a; padding-top: 10px; padding-bottom: 100px;">

				<div style="padding-bottom: 30px;">
					<h6 class="color-1">Contact information </h6>
					
				</div>



				<div>


					<form method="post" @submit.prevent="place_order()">

						<div class="row">

							<div class="col-md-6 col-6 mt-3">
								<label class="color-1">First name</label>
								<input v-model="first_name" type="text" placeholder="Enter first name" class="form-control">
							</div>


							<div class="col-md-6 col-6 mt-3">
								<label class="color-1">Last name</label>
								<input v-model="last_name" type="text" placeholder="Enter last name" class="form-control">
							</div>


							<div class="col-md-6 col-6 mt-3">
								<label class="color-1">Email</label>
								<input v-model="email" type="email" placeholder="Enter email address" class="form-control">
							</div>


							<div class="col-md-6 col-6 mt-3">
								<label class="color-1">Phone</label>
								<input v-model="phone" type="text" placeholder="Enter phone number" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<button class="btn btn-1">Next step</button>
							</div>
							
						</div>
						
					</form>
					

				</div>

				
				

			</div>
			
		</div>
					
		</div>


	</section>



	<Footer />


</div>




	<div v-if="show_modal" style="position: fixed; width: 100%; right: 0; top: 0; padding-top: 200px;">

		<div class="row">

			<div class="col-md-4"></div>
			<div class="col-md-4">
				
				<div style="background-color:#fff;" class="shadow-lg rounded p-3 m-3">
					<h5>How to pay via e-transfer</h5>
					<p> <i class="bi bi-check color-2"></i> Go to e-transfer</p>
					<p> <i class="bi bi-check color-2"></i> Pay 180CAD to <b>Reservations@vibeslounge.ca</b> </p>

					<button @click="next_step()" class="btn btn-1"> {{text}} </button>


					<div style="text-align:right;">
						<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window</button>
					</div>

				</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</template>


<script>
	import Footer from './Footer'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	export default{
		name : 'checkout',
		components : {Footer},
		data(){
			return{
				first_name : '',
				last_name : '',
				email : '',
				phone : '',
				show_modal : false,
				text : 'Place order',
				disabled : false

			}
		},

		methods : {

			async next_step(){

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/place-order',{
					picked_table : this.$store.state.picked_table,
					picked_date : this.$store.state.picked_date,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone
				}).then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})
				localStorage.clear()
				this.$store.state.picked_date = ''
				this.$store.state.picked_table = ''
				this.text = 'Place order'
				this.disabled = false
				this.$router.push('/success')
			},

			place_order(){

				if(!this.first_name){
					Swal.fire({
				  title: "Error",
				  text: "First name is required",
				  icon: "error"
				})
					return
				}


				if(!this.last_name){
					Swal.fire({
				  title: "Error",
				  text: "Last name is required",
				  icon: "error"
				})
					return
				}


				if(!this.email){
					Swal.fire({
				  title: "Error",
				  text: "Email is required",
				  icon: "error"
				})
					return
				}

				if(!this.phone){
					Swal.fire({
				  title: "Error",
				  text: "Phone is required",
				  icon: "error"
				})
					return
				}


				this.show_modal = true



			}
		}

	}

</script>



