import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://vibeslounge.ca/api/',
    picked_date : localStorage.getItem('picked_date'),
    picked_table : localStorage.getItem('picked_table'),
    first_name : localStorage.getItem('first_name'),
    last_name : localStorage.getItem('last_name'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
