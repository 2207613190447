<template>
	


	<section style="padding-top: 50px;">
		

		<div class="container ">

		<h1 style="color: #f1f1f1;">Table Reservation</h1>


		<div class="row shadow">

			<div class="col-md-6" style="background-image: url('/assets/images/gallery-02.jpeg'); background-size: cover; background-position: center; min-height: 300px;"></div>
			<div class="col-md-6" style="background-color: #1a1a1a; padding-top: 0px; padding-bottom: 100px;">

				<div style="text-align:right; padding-bottom: 50px;">
					<button class="btn color-2"><b> <i class="bi bi-telephone-fill"></i> reservations@vibeslounge.ca</b></button>
					<button class="btn color-2"><b> <i class="bi bi-telephone-fill"></i> +18408412569</b></button>
				</div>

				<img src="/assets/images/vibes-logo.png" class="logo">
				<img src="/assets/images/vibes-logo.png" class="logo" style="opacity: 0.2;">
				<img src="/assets/images/vibes-logo.png" class="logo" style="opacity: 0.1;">
				<h4 class="mt-3 color-1">Table reservation </h4>


				<div class="row">

					<div class="col-md-8 col-6">
						<label class="color-1">Pick your date</label>
						<input v-model="picked_date" type="date" class="form-control">
					</div>

					<div class="col-md-4 col-6">
						<label>.</label><br>
					<button @click="check_table()" class="btn btn-1 w-100">Get started</button>	
					</div>
					
				</div>

			</div>
			
		</div>
					
		</div>


	</section>



	<Footer />


</template>


<script>
	import Footer from './Footer'
	import Swal from 'sweetalert2'

	export default{
		name : 'home',
		components : {Footer},
		data(){
			return{
				picked_date : null,
			}
		},
		methods : {
			check_table(){

				if(!this.picked_date){
					Swal.fire({
				  title: "Error",
				  text: "Please pick a date",
				  icon: "error"
				})
					return
				}
				this.$store.state.picked_date = this.picked_date
				localStorage.setItem('picked_date',this.picked_date)
				this.$router.push('/tables')
			}
		}
	}

</script>



