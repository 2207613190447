import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import Tables from '../components/Tables'
import Checkout from '../components/Checkout'
import Success from '../components/Success'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

   {
    path: '/tables',
    name: 'tables',
    component: Tables
  },

  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },

    {
    path: '/success',
    name: 'success',
    component: Success
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
