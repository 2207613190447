<template>
	


	<section style="padding-top: 50px;">
		

		<div class="container ">

		<router-link to="/" class="btn p-0 color-2"><b> <i class="bi bi-chevron-left"></i> Back</b> </router-link>

		<h1 style="color: #f1f1f1;">Table Reservation</h1>


		<div class="row shadow">

			<div class="col-md-6" style="background-image: url('/assets/images/gallery-02.jpeg'); background-size: cover; background-position: center; min-height: 300px;"></div>
			<div class="col-md-6" style="background-color: #1a1a1a; padding-top: 100px; padding-bottom: 100px;">


				<div style="text-align: center;">

				<h1 class="color-2"> <i class="bi bi-check-circle"></i> </h1>
				<h5 class="color-2">Order submitted successfully</h5>


				<router-link class="btn btn-1" to="/"> <i class="bi bi-x"></i> Close</router-link>				
					

				</div>

				
				

			</div>
			
		</div>
					
		</div>


	</section>



	<Footer />


</template>


<script>
	import Footer from './Footer'
	import Swal from 'sweetalert2'
	export default{
		name : 'checkout',
		components : {Footer},
		data(){
			return{
				first_name : '',
				last_name : '',
				email : '',
				phone : ''

			}
		},

		methods : {
			place_order(){

				if(!this.first_name){
					Swal.fire({
				  title: "Error",
				  text: "First name is required",
				  icon: "error"
				})
					return
				}


				if(!this.last_name){
					Swal.fire({
				  title: "Error",
				  text: "Last name is required",
				  icon: "error"
				})
					return
				}


				if(!this.email){
					Swal.fire({
				  title: "Error",
				  text: "Email is required",
				  icon: "error"
				})
					return
				}

				if(!this.phone){
					Swal.fire({
				  title: "Error",
				  text: "Phone is required",
				  icon: "error"
				})
					return
				}

				

			}
		}

	}

</script>



